import React from 'react';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import './App.css';
import './design/color.css';
import './design/typography.css';
import './design/utils.css';
import "react-multi-carousel/lib/styles.css";
import 'react-phone-number-input/style.css';
import Root from "./root";
import ReactGA from 'react-ga';
const ReactPixel = require('react-facebook-pixel');

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

class App extends React.Component {
  componentDidMount() {
    // Google Analytics
    ReactGA.initialize('G-KQ7MTL0SY1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Document Title
    document.title = "ashreya";

    // Razorpay Script
    const razorpayScript = document.createElement("script");
    razorpayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(razorpayScript);

    // Facebook Pixel
    ReactPixel.default.init('1162281664583949');

    // Schema Markup
    // const schemaScript = document.createElement("script");
    // schemaScript.type = "application/ld+json";
    // schemaScript.text = JSON.stringify({
    //   "@context": "https://schema.org",
    //   "@type": "SiteNavigationElement",
    //   "name": "Courses",
    //   "url": "https://coaching.ashreya.com/"
    // });
    // document.head.appendChild(schemaScript);
  }

  render() {
    return (
      <Provider store={store}>
        <Root />
      </Provider>
    );
  }
}

export default App;
